<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa vuokrakohdetta</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="tab">
          <v-btn value="0"> Perustiedot </v-btn>
          <v-btn v-if="isAuthorized('account', 'publicSettings')" value="1">
            Julkiset asetukset
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!--  Basic form -->
    <apartment-form v-show="tab == '0'" save-btn-text="Tallenna vuokrakohde"></apartment-form>
    <!--  Public form -->
    <apartment-public-settings
      v-show="tab == '1'"
      save-btn-text="Tallenna vuokrakohde"
    ></apartment-public-settings>
  </div>
</template>

<script>
import ApartmentForm from "../../components/Apartment/ApartmentForm";
import ApartmentPublicSettings from "../../components/Apartment/ApartmentPublicSettings";
import { mapActions, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  title: "Muokkaa vuokrakohdetta",

  mixins: [mixins],

  components: {
    ApartmentForm,
    ApartmentPublicSettings,
  },

  data() {
    return {
      tab: 0,
    };
  },

  created() {
    this.setLoading(true);
    this.getOneApartment(this.$route.params.id);
    this.getAllCondominiums({ url: "/api/v1/condominium/getAll", data: {} });
  },

  methods: {
    ...mapActions("condominium", ["getAllCondominiums"]),
    ...mapActions("apartment", ["getOneApartment"]),
    ...mapMutations("apartment", ["setLoading"]),
  },
};
</script>

<style scoped></style>
